<template>
	<div class="product" :class="show?'ish5':''">
		<div class="htop">
		<mtop />
		</div>
		<!-- <div :class="show?'ish5':''"></div> -->
		<div class="banner">
				<img
            src="../assets/ximg/banner-nft@2x.png"
            alt="szzc"
            class="simg"
          />
		</div>
		<div class="nft">
			<div class="Gtitle Gwidth">
				<h1>万物皆可NFT</h1>
				<p>
					无论是体育、艺术、游戏、博物馆、餐饮、快消、奢侈品、音乐、地产家居、服装、零售都能结合
				</p>
			</div>
			<ul class="nft-content Gwidth">
				<li>
					<router-link to="">
						<img
							class="thumb"
							src="@/assets/images/bj-bwg.png"
							alt="博物馆"
						/>
						<div class="ico">
							<img
								src="@/assets/ico/nft-ico-1.png"
								alt="博物馆"
							/>
							<p>博物馆</p>
						</div>
					</router-link>
				</li>
				<li>
					<router-link to="">
						<img
							class="thumb"
							src="@/assets/ximg/tp-02@2x.png"
							alt="艺术"
						/>
						<div class="ico">
							<img src="@/assets/ico/nft-ico-2.png" alt="艺术" />
							<p>艺术</p>
						</div>
					</router-link>
				</li>
				<li>
					<router-link to="">
						<img
							class="thumb"
							src="@/assets/ximg/tp-03@2x.png"
							alt="音乐"
						/>
						<div class="ico">
							<img src="@/assets/ico/nft-ico-3.png" alt="音乐" />
							<p>音乐</p>
						</div>
					</router-link>
				</li>
				<li>
					<router-link to="">
						<img
							class="thumb"
							src="@/assets/ximg/tp-04@2x.png"
							alt="地产家居"
						/>
						<div class="ico">
							<img
								src="@/assets/ico/nft-ico-4.png"
								alt="地产家居"
							/>
							<p>地产家居</p>
						</div>
					</router-link>
				</li>
				<li>
					<router-link to="">
						<img
							class="thumb"
							src="@/assets/images/bj-yx.png"
							alt="游戏"
						/>
						<div class="ico">
							<img src="@/assets/ico/nft-ico-5.png" alt="游戏" />
							<p>游戏</p>
						</div>
					</router-link>
				</li>
				<li>
					<router-link to="">
						<img
							class="thumb"
							src="@/assets/ximg/bj-more@2x.png"
							alt="更多"
						/>
						<div class="ico">
							<img src="@/assets/ico/nft-ico-6.png" alt="更多" />
							<p class="more">更多</p>
						</div>
					</router-link>
				</li>
			</ul>
		</div>
		<div class="more-type">
			<div class="Gtitle Gwidth">
				<h1>多样前沿功能</h1>
				<p>满足平台个性化需求，更多功能支持定制开发</p>
			</div>
			<div ref="mySwiper" class="swiper-container Gwidth" id="more">
				<ul class="swiper-wrapper more-type-content">
					<li
						class="swiper-slide"
						:class="{ active: current == 1 }"
						@click="current = 1"
					>
						<div class="cont">
							<h4>45项专业功能</h4>
							<p>
								拥有全网最丰富的专业功能，让您轻松应对各类NFT业务
							</p>
						</div>
					</li>
					<li
						class="swiper-slide"
						:class="{ active: current == 2 }"
						@click="current = 2"
					>
						<div class="cont">
							<h4>20项<br />基础应用</h4>
							<p>助您迅速搭建平台，即刻开启NFT之旅</p>
						</div>
					</li>
					<li
						class="swiper-slide"
						:class="{ active: current == 3 }"
						@click="current = 3"
					>
						<div class="cont">
							<h4>5款<br />应用配置</h4>
							<p>
								内容配置｜链配置｜支付配置｜实名认证配置｜短信配置
							</p>
						</div>
					</li>
					<li
						class="swiper-slide"
						:class="{ active: current == 4 }"
						@click="current = 4"
					>
						<div class="cont">
							<h4>5款<br />管理功能</h4>
							<p>
								藏品管理｜订单管理｜会员管理｜商户管理｜财务管理
							</p>
						</div>
					</li>
					<li
						class="swiper-slide"
						:class="{ active: current == 5 }"
						@click="current = 5"
					>
						<div class="cont">
							<h4>7款<br />营销玩法</h4>
							<p>
								合成｜空投｜优先购｜兑换码｜抽签｜邀新｜竞拍出价｜抢购｜实物兑付
							</p>
						</div>
					</li>
					<li
						class="swiper-slide"
						:class="{ active: current == 6 }"
						@click="current = 6"
					>
						<div class="cont">
							<h4>3款<br />辅助功能</h4>
							<p>快速购买｜链余额｜会员管理｜数据概览</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="ecology">
			<div class="Gtitle Gwidth">
				<h1>数字藏品全模式生态链路</h1>
			</div>
			<div class="ecology-top Gwidth">
				<div class="ecology-top-head">Intemet接入</div>
				<ul>
					<li>IOS/Android</li>
					<li>H5</li>
					<li>微信小程序</li>
					<li>支付宝小程序</li>
					<li>微信公众号</li>
				</ul>
			</div>
			<div class="ecology-center Gwidth">
				<div class="ecology-top-head">
					<p>变现方式</p>
					<p>静态资源</p>
				</div>
				<div class="ecology-center-content">
					<div class="ecology-center-content-left">
						<ul>
							<li>接入服务</li>
							<li>认证</li>
							<li>API网关</li>
							<li>运营网关</li>
							<li>前端服务</li>
							<li>uniapp</li>
							<li>前端服务</li>
							<li>uniapp</li>
						</ul>
					</div>
					<div class="ecology-center-content-center">
						<ul>
							<li>抢购管理</li>
							<li>申购中签</li>
							<li>空投管理</li>
							<li>合成管理</li>
							<li>盲盒管理</li>
							<li>邀请好友</li>
							<li>兑换码管理</li>
							<li>帮助中心</li>
							<li>藏品转赠</li>
							<li>会员优先购</li>
							<li>积分管理</li>
							<li>藏品寄售</li>
						</ul>
					</div>
					<div class="ecology-center-content-right">
						<ul>
							<li>RESTFULAPI</li>
							<li>NOSQL</li>
							<li>业务层</li>
							<li>Redis</li>
						</ul>
						<ul>
							<li>超级链</li>
							<li>文昌链</li>
							<li>私链</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="ecology-bottom Gwidth">
				<ul>
					<li>REDIS</li>
					<li>NOSQL</li>
					<li>负载均衡/Nginx</li>
					<li>云服务器</li>
					<li>云存储</li>
				</ul>
			</div>
		</div>
		<div class="select">
			<div class="Gtitle Gwidth">
				<h1>SAAS部署or独立部署，企业更适合哪个</h1>
			</div>
			<div class="select-content Gwidth">
				<div class="select-content-item">
					<div class="select-content-item-head">
						<h5>SAAS版本</h5>
						<p>中小型企业可按需所用、无需维护</p>
					</div>
					<ul>
						<li>按年付费，性价比高</li>
						<li>官方统一部署，维护成本低</li>
						<li>扩展升级，SAAS系统周期性持续性进行迭代优化</li>
					</ul>
				</div>
				<div class="select-content-item">
					<div class="select-content-item-head">
						<h5>独立部署</h5>
						<p>企业可掌控完整的私密性数据安全</p>
					</div>
					<ul>
						<li>不依赖三方服务器与数据库</li>
						<li>自由拓展器，分布式部署</li>
						<li>API接口，第三方工具随时对接</li>
						<li>提供源码部署，支持二次开发</li>
						<li>数据更安全，更可控</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="income">
			<div class="Gtitle Gwidth">
				<h1>专属数字藏品平台能为企业带来什么？</h1>
				<p>品牌IP变现、创新营销方式、培育新势力用户</p>
			</div>
			<ul class="Gwidth">
				<li>
					<div class="ico">
						<img src="@/assets/ico/icon-ip.png" alt="IP资源变现" />
					</div>
					<h5>IP资源变现</h5>
					<p>
						实现IP数字化虚拟资产变现，包括但不限于影视、音乐、体育、古玩、消费等行业
					</p>
				</li>
				<li>
					<div class="ico">
						<img src="@/assets/ico/icon-xlzz.png" alt="销量增长" />
					</div>
					<h5>销量增长</h5>
					<p>多样营销工具促进产品销量增长</p>
				</li>
				<li>
					<div class="ico">
						<img src="@/assets/ico/icon-syfn.png" alt="商业赋能" />
					</div>
					<h5>商业赋能</h5>
					<p>打通实物消费品、艺术品链上商城</p>
				</li>
				<li>
					<div class="ico">
						<img
							src="@/assets/ico/icon-xf.png"
							alt="消费场景重构"
						/>
					</div>
					<h5>消费场景重构</h5>
					<p>虚拟+实物、线上+线下联动NFT带来全新消费场景</p>
				</li>
				<li>
					<div class="ico">
						<img src="@/assets/ico/icon-syfn.png" alt="打造私欲" />
					</div>
					<h5>打造私欲</h5>
					<p>将公域流量引入私域平台，实现流量变现、提高企业估值</p>
				</li>
				<li>
					<div class="ico">
						<img src="@/assets/ico/icon-stkz.png" alt="生态扩展" />
					</div>
					<h5>生态扩展</h5>
					<p>生态商家加入平台，建立商铺，形成生态</p>
				</li>
			</ul>
		</div>
		<div class="process">
			<div class="Gtitle Gwidth">
				<h1>项目流程</h1>
				<p>每一个流程都由专业人员操作执行，并有严格质量控制</p>
			</div>
			<ul class="Gwidth">
				<li>
					<div class="ico">
						<img src="@/assets/ico/icon-ywfw.png" alt="咨询签约" />
					</div>
					<h5>咨询签约</h5>
					<p>咨询签约并开设系统软件后台账号及功能</p>
				</li>
				<li>
					<div class="ico">
						<img
							src="@/assets/ico/icon-pxfw-2.png"
							alt="培训服务"
						/>
					</div>
					<h5>培训服务</h5>
					<p>系统全维度的培训</p>
				</li>
				<li>
					<div class="ico">
						<img
							src="@/assets/ico/icon-ptdj-3.png"
							alt="平台搭建"
						/>
					</div>
					<h5>平台搭建</h5>
					<p>平台相关内容编辑及管理</p>
				</li>
				<li>
					<div class="ico">
						<img
							src="@/assets/ico/icon-sjfx-4.png"
							alt="上架发行"
						/>
					</div>
					<h5>上架发行</h5>
					<p>铸造发行</p>
				</li>
				<li>
					<div class="ico">
						<img src="@/assets/ico/icon-ywfw.png" alt="运维服务" />
					</div>
					<h5>运维服务</h5>
					<p>免费跟踪运维服务</p>
				</li>
			</ul>
			<div class="more">
				<router-link to="">查看演示版</router-link>
			</div>
		</div>
		<mbottom />
	</div>
</template>

<script>
	import Swiper from "swiper";
	import "swiper/swiper.css";
	import mtop from "../components/top.vue";
	import mbottom from "../components/bottom.vue";
	export default {
		name: "product",
		components: {
			mtop,
			mbottom,
		},
		data() {
			return {
				current: 1,
				show:false
			};
		},
		mounted() {
			this.initSwiper();
			this.initMore();
			  if (this._isMobile()) {
				this.show=true;
				} else {
				this.show=this.$store.getters.phone;
				}  
		},
	methods: {
			      _isMobile() {
   let flag = navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
 return flag;   
},
			initSwiper() {
				new Swiper("#banner", {
					autoHeight: "true",
					autoplay: {
						delay: 5000,
						stopOnLastSlide: false,
						disableOnInteraction: false,
					},
				});
			},
			initMore() {
				// eslint-disable-next-line
				let vueComponent = this; //获取vue组件实例
				//一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
				new Swiper("#more", {
					loop: false, // 循环模式选项
					slidesPerView: "auto",
					autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
.product{
	.htop{
		    height: 70px;
	    }	
        .banner{
    width: 100%;
    font-size: 0;
    .simg{
        width: 100%;
        height: auto;
    }
  }

}
.ish5{
	.htop{
            height:125px;
        }
        .banner{
    width: 100%;    
    font-size: 0;
    display: flex;
        align-items: center;
        justify-content: center;
    .simg{
        width: 1000px;
        height: auto;
    }
  }
}
	@media screen and (min-width: 0px) {
	
		.Gtitle {
			text-align: center;
			h1 {
				font-size: 32px;
				font-weight: 500;
				color: #000000;
				line-height: 45px;
			}
			p {
				font-size: 28px;
				font-weight: 400;
				color: #969799;
				line-height: auto;
			}
		}
		.nft {
			padding: 30px 0 50px 0;
			&-content {
				li {
					font-size: 0;
					position: relative;
					margin-top: 20px;
					.thumb {
						width: 100%;
					}
					.ico {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						// background-color: rgba($color: #000000, $alpha: 0.3);
						img {
							width: 32px;
						}
						p {
							font-size: 32px;
							margin-top: 10px;
							font-weight: 400;
							color: #ffffff;
							&.more {
								color: #2b6bff;
							}
						}
					}
				}
			}
		}
		.more-type {
			background-color: #f5f6fa;
			padding: 30px 0 50px 0;
			&-content {
				// display: flex;
				// flex-wrap: wrap;
				margin-top: 30px;
				li {
					width: calc(25% - 8px);
					width: 140px;
					height: 376px;
					border-radius: 8px;
					margin-right: 10px;
					padding: 40px 20px;
					box-sizing: border-box;
					transition: 0.35s;
					.cont {
						h4 {
							font-size: 36px;
							font-weight: 500;
							color: #ffffff;
						}
						p {
							font-size: 12px;
							margin-top: 13px;
							font-weight: 400;
							color: #ffffff;
							opacity: 0;
						}
					}
					&:nth-child(1) {
						//
						background: linear-gradient(
								180deg,
								#1269ed 0%,
								rgba(19, 106, 237, 0.96) 36%,
								rgba(255, 255, 255, 0) 100%
							),
							url(~@/assets/images/bj-01.png) center/cover
								no-repeat;
						margin-right: 12px;
					}
					&:nth-child(2) {
						// width: 140px;
						// margin-right: 0;
						background: linear-gradient(
								180deg,
								#304c94 0%,
								rgba(48, 75, 145, 0.96) 36%,
								rgba(48, 76, 148, 0) 100%
							),
							url(~@/assets/images/bj-02.png) center/cover
								no-repeat;
					}
					&:nth-child(3) {
						background: linear-gradient(
								180deg,
								#0fbacd 0%,
								rgba(15, 186, 205, 0.96) 37%,
								rgba(15, 186, 205, 0) 100%
							),
							url(~@/assets/images/bj-03.png) center/cover
								no-repeat;
					}
					&:nth-child(4) {
						background: linear-gradient(
								181deg,
								#553dea 0%,
								rgba(147, 132, 242, 0.96) 36%,
								rgba(255, 255, 255, 0) 100%
							),
							url(~@/assets/images/bj-04.png) center/cover
								no-repeat;
					}
					&:nth-child(5) {
						background: linear-gradient(
								180deg,
								#1269ed 0%,
								rgba(19, 106, 237, 0.96) 36%,
								rgba(255, 255, 255, 0) 100%
							),
							url(~@/assets/images/bj-05.png) center/cover
								no-repeat;
					}
					&:nth-child(6) {
						background: linear-gradient(
								180deg,
								#304c94 0%,
								rgba(48, 75, 145, 0.96) 36%,
								rgba(48, 76, 148, 0) 100%
							),
							url(~@/assets/images/bj-06.png) center/cover
								no-repeat;
						margin-right: 0;
					}
				}
				.active {
					width: calc(100% - 152px);
					max-width: 440px;
					.cont {
						p {
							opacity: 1;
						}
					}
				}
			}
		}
		.ecology {
			padding: 30px 0 50px 0;
			position: relative;
			z-index: 1;
			&::after {
				content: "";
				width: 271px;
				height: 271px;
				background: url(~@/assets/images/icon-lifangti.png) center
					center no-repeat;
				background-size: 271px 271px;
				position: absolute;
				left: 38px;
				bottom: 145px;
				z-index: -1;
			}
			&::before {
				content: "";
				width: 231px;
				height: 231px;
				background: url(~@/assets/images/icon-lifangti.png) center
					center no-repeat;
				background-size: 231px 231px;
				position: absolute;
				right: 51px;
				top: 188px;
				z-index: -1;
			}
			&-top {
				margin-top: 30px;
				box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
				border-radius: 0px 0px 4px 4px;
				&-head {
					background: linear-gradient(
						262deg,
						#56a4ff 0%,
						#2b6bff 100%
					);
					border-radius: 0px 0px 4px 4px;
					font-size: 16px;
					padding: 5px 0;
					font-weight: 500;
					color: #ffffff;
					text-align: center;
					display: flex;
					justify-content: center;
					p {
						flex: 1;
						&:nth-child(1) {
							border-right: 1px solid
								rgba($color: #ffffff, $alpha: 0.5);
						}
					}
				}
				ul {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					padding: 10px 15px;
					li {
						font-size: 12px;
						width: calc(33.33% - 10px);
						height: 44px;
						background: #f4f8fb;
						border-radius: 4px;
						font-weight: 400;
						color: #1d2129;
						margin-bottom: 10px;
						text-align: center;
						line-height: 44px;
						margin-right: 10px;
					}
				}
			}
			&-center {
				margin-top: 32px;
				&-content {
					&-left,
					&-right,
					&-center {
						margin-top: 24px;
					}
				}
				ul {
					background: #ffffff;
					box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
					border-radius: 4px;
					padding: 14px;
					display: flex;
					flex-wrap: wrap;
					li {
						// max-width: 120px;
						width: calc(33.33% - 18px);
						min-height: 53px;
						background: #f4f8fb;
						border-radius: 4px;
						box-sizing: border-box;
						font-size: 12px;
						padding: 16px 20px;
						font-weight: 400;
						color: #1d2129;
						margin: 0 9px;
						margin-bottom: 10px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			&-bottom {
				margin-top: 30px;
				ul {
					background: #ffffff;
					box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
					border-radius: 4px;
					box-sizing: border-box;
					padding: 20px 20px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					li {
						font-size: 16px;
						font-weight: 500;
						color: #1d2129;
						padding-left: 32px;
						width: calc(50% - 5px);
						box-sizing: border-box;
						margin-bottom: 10px;
						&:nth-child(1) {
							background: url(~@/assets/ico/icon-redis.png) left
								center no-repeat;
							background-size: 27px 27px;
						}
						&:nth-child(2) {
							background: url(~@/assets/ico/icon-nosql.png) left
								center no-repeat;
							background-size: 27px 27px;
						}
						&:nth-child(3) {
							background: url(~@/assets/ico/icon-fzjh.png) left
								center no-repeat;
							background-size: 27px 27px;
						}
						&:nth-child(4) {
							background: url(~@/assets/ico/icon-yfwq.png) left
								center no-repeat;
							background-size: 27px 27px;
						}
						&:nth-child(5) {
							background: url(~@/assets/ico/icon-ycc.png) left
								center no-repeat;
							background-size: 27px 27px;
						}
					}
				}
			}
		}
		.select {
			background-color: #f5f6fa;
			padding: 30px 0 50px 0;
			&-content {
				&-item {
					background: #ffffff;
					border-radius: 8px;
					margin-top: 20px;
					&:nth-child(2) {
						.select-content-item-head {
							background: url(~@/assets/images/bj-dlbs.png)
								center/cover no-repeat;
						}
					}
					&-head {
						padding: 20px 0;
						background: url(~@/assets/images/bj-saas.png)
							center/cover no-repeat;
						text-align: center;
						h5 {
							font-size: 18px;
							font-weight: 500;
							color: #ffffff;
						}
						p {
							font-size: 14px;
							font-weight: 400;
							color: #ffffff;
						}
					}
					ul {
						padding: 20px 30px;
						li {
							font-size: 16px;
							font-weight: 400;
							color: #323233;
							position: relative;
							margin-bottom: 20px;
							&::after {
								content: "";
								width: 4px;
								height: 4px;
								border-radius: 50%;
								background-color: #969799;
								position: absolute;
								left: -15px;
								top: 50%;
								transform: translateY(-50%);
							}
						}
					}
				}
			}
		}
		.income {
			padding: 30px 0 50px 0;
			ul {
				margin-top: 30px;
				li {
					background: #ffffff;
					border-radius: 2px;
					border: 1px solid #ebedf0;
					padding: 22px 21px;
					margin-top: 15px;
					.ico {
						img {
							width: 52px;
							height: 53px;
						}
					}
					h5 {
						font-size: 16px;
						margin-top: 9px;
						font-weight: 500;
						color: #1d2129;
					}
					p {
						font-size: 16px;
						margin-top: 8px;
						font-weight: 400;
						color: #646566;
						line-height: 24px;
					}
				}
			}
		}
		.process {
			padding: 30px 0 50px 0;
			background: url(~@/assets/images/bj-xmlc.png) center/cover no-repeat;
			ul {
				display: flex;
				flex-wrap: wrap;
				margin-top: 30px;
				li {
					width: 50%;
					text-align: center;
					margin-bottom: 20px;

					.ico {
						font-size: 0;
						img {
							max-width: 114px;
						}
					}
					h5 {
						font-size: 16px;
						font-weight: 500;
						color: #1d2129;
						line-height: 22px;
						margin-top: 24px;
					}
					p {
						font-size: 16px;
						font-weight: 400;
						color: #646566;
						margin-top: 8px;
					}
				}
			}
		}
		.more {
			margin-top: 75px;
			a {
				width: 128px;
				height: 40px;
				border-radius: 2px;
				border: 1px solid #2b6bff;
				font-size: 16px;
				font-weight: 400;
				color: #2b6bff;
				line-height: 40px;
				display: block;
				margin: 0 auto;
				text-align: center;
				&:hover {
					background-color: #2b6bff;
					color: #ffffff;
				}
			}
		}
	}
	@media screen and (min-width: 760px) {
		.nft {
			&-content {
				display: flex;
				flex-wrap: wrap;
				li {
					width: calc(50% - 10px);
					margin-right: 20px;
					&:nth-child(2n) {
						margin-right: 0;
					}
				}
			}
		}
		.more-type {
			&-content {
				li {
					// width: calc(33.33% - 8px);
					&:nth-child(1) {
						// width: calc(100% - 304px);
						margin-right: 12px;
					}
					&:nth-child(2) {
						margin-right: 12px;
					}
					&:nth-child(3) {
						// width: 140px;
						// margin-right: 0;
					}
				}
			}
		}
		.ecology {
			&-bottom {
				ul {
					li {
						width: auto;
						margin-bottom: 0;
					}
				}
			}
		}
		.select {
			&-content {
				margin-top: 40px;
				display: flex;
				&-item {
					flex: 1;
					margin: 0 10px;
					&:nth-child(2) {
						box-shadow: 0px 0px 85px 0px rgba(29, 33, 41, 0.05);
						transform: translateY(-10px);
					}
				}
			}
		}
		.income {
			ul {
				display: flex;
				flex-wrap: wrap;
				li {
					width: calc(50% - 10px);
					box-sizing: border-box;
					margin-right: 20px;
					margin-bottom: 10px;
					&:nth-child(2n) {
						margin-right: 0;
					}
				}
			}
		}
		.process {
			ul {
				li {
					width: 33.33%;
				}
			}
		}
	}
	@media screen and (min-width: 820px) {
		.ecology {
			&-top {
				ul {
					li {
						font-size: 16px;
					}
				}
			}
		}
	}
	@media screen and (min-width: 1100px) {
		.Gtitle {
			h1 {
				font-size: 32px;
			}
			p {
				font-size: 16px;
				margin-top: 14px;
			}
		}
		.nft {
			padding: 80px 0 100px 0;
			&-content {
				display: flex;
				flex-wrap: wrap;
				margin-top: 60px;
				li {
					width: calc(33.33% - 15px);
					margin-right: 22px;
					overflow: hidden;
					.thumb {
						transition: 0.35s;
					}
					&:hover {
						.thumb {
							transform: scale(1.1);
						}
					}
					&:nth-child(2n) {
						margin-right: 22px;
					}
					&:nth-child(3n) {
						margin-right: 0px;
					}
				}
			}
		}
		.more-type {
			padding: 80px 0 100px 0;
			&-content {
				margin-top: 60px;
				li {
					// width: 140px;
					&:nth-child(1) {
						// width: calc(100% - 760px);
						margin-right: 12px;
					}
					&:nth-child(2) {
						margin-right: 12px;
					}
					&:nth-child(3) {
						margin-right: 12px;
					}
					&:nth-child(6) {
						margin-right: 0;
					}
				}
			}
		}
		.ecology {
			padding: 80px 0 100px 0;
			&-top {
				margin-top: 60px;
				&-head {
					font-size: 24px;
					padding: 24px 0;
				}
				ul {
					padding: 24px 38px;
					justify-content: space-between;
					li {
						width: calc(20% - 10px);
						height: 64px;
						margin-bottom: 0px;
						line-height: 64px;
					}
				}
			}
			&-center {
				margin-top: 32px;
				&-content {
					display: flex;
					&-left,
					&-right,
					&-center {
						margin-top: 24px;
						flex: 1;
					}
					ul {
						padding: 14px 14px 0 14px;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						li {
							// max-width: 120px;
							width: calc(50% - 18px);
							padding: 10px;
							&:hover {
								color: #ff0000;
							}
						}
					}
					&-center {
						flex: 2;
						margin-left: 24px;
						margin-right: 24px;
						ul {
							li {
								width: calc(33.33% - 18px);
							}
						}
					}
					&-right {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						ul {
							padding: 14px;
							&:nth-child(2) {
								li {
									width: calc(33.33% - 18px);
								}
							}
						}
					}
				}
			}
		}
		.select {
			padding: 80px 0 100px 0;
			&-content {
				margin-top: 60px;
				&-item {
					flex: 1;
					margin: 0 24px;
					&:nth-child(2) {
						transform: translateY(-20px);
					}
				}
			}
		}
		.income {
			padding: 80px 0 100px 0;
			ul {
				margin-top: 60px;
				li {
					width: calc(33.33% - 16px);
					margin-right: 24px;
					margin-bottom: 14px;
					transition: 0.35s;
					&:hover {
						transform: translateY(-10px);
					}
					&:nth-child(2n) {
						margin-right: 24px;
					}
					&:nth-child(3n) {
						margin-right: 0;
					}
				}
			}
		}
		.process {
			padding: 80px 0 100px 0;
			ul {
				margin-top: 60px;
				li {
					width: 20%;
					&:last-child {
						.ico {
							&::after {
								width: 0;
								height: 0;
							}
							&::before {
								content: "";
								width: 0;
								height: 0;
								background: none;
							}
						}
					}
					.ico {
						font-size: 0;
						position: relative;
						z-index: 1;

						&::after {
							content: "";
							width: 100%;
							height: 2px;
							position: absolute;
							left: 50%;
							top: 50%;
							z-index: -1;
							background-color: #ceddff;
							transform: translateY(-50%);
						}
						&::before {
							content: "";
							width: 19px;
							height: 19px;
							background: url(~@/assets/ico/icon-product-jiantou.png)
								center center no-repeat;
							background-size: 19px 19px;
							position: absolute;
							left: calc(100% - 8px);
							top: 50%;
							transform: translateY(-50%);
						}
						img {
							max-width: 114px;
						}
					}
				}
			}
		}
	}
	@media screen and (min-width: 1300px) {
		.ecology {
			padding: 80px 0 100px 0;
			&-top {
				margin-top: 60px;
				&-head {
					font-size: 24px;
					padding: 24px 0;
				}
				ul {
					padding: 24px 38px;
					justify-content: space-between;
					li {
						width: calc(20% - 10px);
						height: 64px;
						margin-bottom: 0px;
						line-height: 64px;
					}
				}
			}
			&-center {
				&-content {
					ul {
						li {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
		.ish5{
			.banner {
			// padding-top: 140px;
			.swiper-slide{
				display: flex;
				align-items: center;
				justify-content: center;
			}
			img {
				width: 150%;
			}
		}
		}
</style>
